/**********************************************************************************************
TermsConditions.tsx - TermsConditions is for showing terms& condtion based on calculation type
***********************************************************************************************/
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch, } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./TermsConditions.scss";
import { initialState } from "../../../../store/respiratorStore";
import Modal from "react-bootstrap/Modal";
import Analytics from "../../../../services/analytics";
import TermsConditionsContent from "./TermsConditionsContent";
import { CALCULATION_TYPES, STORE_ACTION_TYPES, RESPIRATOR_STEPS, LABELSKEY_TERMS, ARIA_LABEL, WARNING } from "../../../../helper/constants";
import WarningModal from '../../../common/WarningModal/WarningModal';
import { resizeParent } from "../../../../assets/js/utils";
import { scrollToParentTop } from "../../../../assets/js/utils";
const TermsConditions = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { calculatorType, activeStep, isDisclaimerAgreed } = useSelector((state: any) => state?.respirator?.calculator || {});
    const termsAgreed = useSelector((state: any) => state?.respirator?.calculator?.termsAgreed || false);
    const { ANLYTICS_EVENT_TYPES, PAGE_NAVIGATION, CALCULATION_TYPES_VALUES } = useSelector((state: any) => state?.respirator?.config || {});
    
    //Modal Functionality:
    const [show, setShow] = useState(false);
    const showingWarningModal = useCallback(() => {
        dispatch({
            type: STORE_ACTION_TYPES.CALCULATOR,
            payload: { isDisclaimerAgreed: false },
        });
        setShow(true);
    }, [dispatch]);

    const showDisclaimerDescription = useCallback(() => {
        dispatch({
            type: STORE_ACTION_TYPES.CALCULATOR,
            payload: { isDisclaimerAgreed: true },
        });
    }, [dispatch]);

    const setDisclaimerModalClose = useCallback(() => {
        dispatch({
            type: STORE_ACTION_TYPES.CALCULATOR,
            payload: { isDisclaimerAgreed: false },
        });
        setShow(false);
    }, [dispatch]);
    //Modal Functionality Ends
    
    //Redireting to home if user accessing the page directly
    useEffect(() => {
        if (CALCULATION_TYPES_VALUES?.indexOf(calculatorType) < 0) {
            navigate(PAGE_NAVIGATION.HOME);
        }
    }, [calculatorType, PAGE_NAVIGATION.HOME, CALCULATION_TYPES_VALUES, navigate]);
    
    //Next step base on cauculator type
    const nextStep = useMemo(() => {
        return calculatorType === CALCULATION_TYPES.SERVICE_LIFE ? RESPIRATOR_STEPS.RESPIRATOR_SELECTION : calculatorType === CALCULATION_TYPES.VAPORS ? RESPIRATOR_STEPS.CONTAINMENT_SELECTION : "";
    }, [calculatorType]);
    
    //Next step base on cauculator type
    const activeStepValue = useMemo(() => {
        return activeStep === "" ? calculatorType === CALCULATION_TYPES.SERVICE_LIFE ? RESPIRATOR_STEPS.RESPIRATOR_SELECTION : calculatorType === CALCULATION_TYPES.VAPORS ? RESPIRATOR_STEPS.CONTAINMENT_SELECTION : "" : activeStep;
    }, [activeStep, calculatorType]);
    
    //Read and accept click: Trigerring on Calculator type selection  and storing the details  
    const onReadAndAcceptClick = useCallback((e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
        e.preventDefault();
        dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { termsAgreed: !termsAgreed } });
    }, [termsAgreed, dispatch]);
   
    //Agree/Disagree Selection Button Click: Trigerring on Calculator type selection  and storing the details  
    const onContinueButtonClick = useCallback((isContinue: boolean) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        window.scrollTo(0, 0);
        scrollToParentTop();
        e.preventDefault();
        if (isContinue && termsAgreed) {
            dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { termsAgreed: true, currentStep: nextStep, activeStep: activeStepValue, isDisclaimerAgreed: false } });
            Analytics.sendAnalyticsData(ANLYTICS_EVENT_TYPES?.CALCULATOR_SELECTION);
            calculatorType === CALCULATION_TYPES.SERVICE_LIFE ? navigate(PAGE_NAVIGATION.ServiceLife.Respirator_Selection) : navigate(PAGE_NAVIGATION.Vapors.Contaminant_Selection);
        }
        else if (!isContinue) {
            dispatch({ type: STORE_ACTION_TYPES.CALCULATOR, payload: { ...initialState.calculator, termsAgreed: false, calculatorType: calculatorType } });
            navigate(PAGE_NAVIGATION.HOME);
        }
    }, [calculatorType, termsAgreed, nextStep, activeStepValue, ANLYTICS_EVENT_TYPES?.CALCULATOR_SELECTION, PAGE_NAVIGATION.ServiceLife.Respirator_Selection, PAGE_NAVIGATION.Vapors.Contaminant_Selection, PAGE_NAVIGATION.HOME, dispatch, navigate]);
      //resize parent iframe
      resizeParent();
    return (
        <div className="main-wrapper" role="region" aria-label={t(ARIA_LABEL.RESPIRATOR_CALCULTAION)}>
            <div className="main-heading">{t(LABELSKEY_TERMS.MAIN_HEADING)} <sup>{t(LABELSKEY_TERMS.SUPERSCRIPT_TITLE)}</sup></div>
            <div className="tc-container">
                <div className="content">
                    <div className="heading">{t(LABELSKEY_TERMS.HEADING)}</div>
                    <TermsConditionsContent />
                    <div className="checkbox">
                        <input id="cbReadAndAccept" readOnly type="checkbox" checked={termsAgreed} />
                        <label onClick={onReadAndAcceptClick} htmlFor="cbReadAndAccept"><span>{t(LABELSKEY_TERMS.READ_ACCEPT)}</span></label>
                    </div>
                    <div className="btn-container cta-desktop">
                        <div className={"btn btn-primary"} onClick={onContinueButtonClick(false)}>{t(LABELSKEY_TERMS.DISAGREE)}</div>
                        {calculatorType === CALCULATION_TYPES.SERVICE_LIFE ?
                            <div className={termsAgreed ? "btn btn-secondary" : "btn btn-disabled"} onClick={onContinueButtonClick(true)}>{t(LABELSKEY_TERMS.AGREE)}</div>
                            :
                            <div className={termsAgreed ? "btn btn-secondary" : "btn btn-disabled"} onClick={showingWarningModal}>{t(LABELSKEY_TERMS.AGREE)}</div>
                        }
                    </div>
                    <div className="btn-container cta-mobile">
                        {calculatorType === CALCULATION_TYPES.SERVICE_LIFE ?
                            <div className={termsAgreed ? "btn btn-secondary" : "btn btn-disabled"} onClick={onContinueButtonClick(true)}>{t(LABELSKEY_TERMS.AGREE)}</div>
                            :
                            <div className={termsAgreed ? "btn btn-secondary" : "btn btn-disabled"} onClick={showingWarningModal}>{t(LABELSKEY_TERMS.AGREE)}</div>
                        }
                        <div className={"btn btn-primary"} onClick={onContinueButtonClick(false)}>{t(LABELSKEY_TERMS.DISAGREE)}</div>
                    </div>
                </div>
            </div>
            <Modal show={show}>
                <WarningModal showWarningIcon
                    messageHeader={t(WARNING.ALERT)}
                    message={t(WARNING.DISCLAIMER_HEADLINE)}
                    onClickYes={onContinueButtonClick(true)}
                    onClickNo={showDisclaimerDescription}
                    labelYes={t(WARNING.NO)}
                    labelNo={t(WARNING.YES)}
                    labelOk={t(WARNING.OK)}
                    descriptionBoxMessage={t(WARNING.DISCLAIMER_DESCRIPTION)} 
                    onClickOk={setDisclaimerModalClose}
                    isDecriptionBox={isDisclaimerAgreed}
                />
            </Modal>
        </div>
    );
};

export default TermsConditions;