import React, { useCallback, useEffect, useState } from "react";
import "./ContaminantSelection.scss";
import { Accordion, Form } from 'react-bootstrap';
import '../../../common/ProductGrid/ProductGridTables.scss';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "./Pagination/Pagination.scss";
import Modal from "react-bootstrap/Modal";
import { ARIA_LABEL, CALCULATION_TYPES, LABELKEYS_CONTAINMENT_REVIEW, LABELKEYS_WORKING_CONDITION, PAGINATION_LABEL, PRODUCT_TABLE, REGEX_VALIDATION, STORE_ACTION_TYPES, WARNING } from "../../../../helper/constants";
import NoResults from "../../../common/NoResults/NoResults";
import { ImgWarning } from "../../../../assets/images/cartridge/cartSelection";
import "../../../common/WarningModal/WarningModal.scss";
import WarningModal from "../../../common/WarningModal/WarningModal";
import SearchBox from "../../../common/SearchBox/SearchBox";
import { useGetContaminantsQuery } from "../../../../services/contaminant";
import Loader from "../../../common/Loader/Loader";
import ErrorHandling from "../../../common/ErrorHandling/ErrorHandling";
import { BiChevronDown } from "react-icons/bi";
import { resizeParent } from "../../../../assets/js/utils";
//Define Data JSON Array for displaying data defined by business
const ContaminantSelection = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { rowsPerPageValue, contaminantProductKeyValues, calculatorType } =
    useSelector((state: any) => state?.respirator?.calculator || {});
  const { CONFIG_ROWS_PER_PAGE_OPTION, CONFIG_DEFAULT_PAGE_COLLECTION, CONFIG_MIN_LETTERS_FOR_SEARCH_CONTAMINANT } = useSelector(
    (state: any) => state?.respirator?.config || {}
  );
  //Setting the initial value of product grid
  const [pageNumber, setPageNumber] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [defaultsearch, setDefaultSearch] = useState(false);
  const { showError } = useSelector((state: any) => state?.respirator?.error || {});
  //Search Functionality Click : Triggering the Search Button will display product based on search terms
  const [searchTermValue, setSearchValue] = useState("");
  const [searchTermFind, setsearchTerm] = useState("");
  const [sortDataField, setSortDataField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const { FIELD_CONFIG_CONTAMINANT_SECTION1, FIELD_CONFIG_CONTAMINANT_SECTION2 } = useSelector((state: any) => state?.respirator?.config || {});

  const { data, isLoading } = useGetContaminantsQuery({
    startIndex: pageNumber + 1,
    rows: rowsPerPageValue,
    sortBy: sortDataField,
    sortOrder: sortOrder,
    searchTerm: defaultsearch === true ? searchTermFind : "",
    searchField: "",
  });

  const [dataProduct, setDataProductList] = useState(
    data?.result?.vaporList || []
  );
  const productLength = data?.result?.totalRecords;
  const pages = Math.ceil(productLength / rowsPerPageValue);
  const [numberOfPages, setNumberOfPages] = useState(pages);
  const [lastPage, setLastPage] = useState(
    numberOfPages < CONFIG_DEFAULT_PAGE_COLLECTION ? numberOfPages : CONFIG_DEFAULT_PAGE_COLLECTION
  );
  const pageLength = (pageNumber + 1) * rowsPerPageValue;
  const startPageIndex =
    pageNumber === 0
      ? 1
      : (pageLength < productLength ? pageLength : productLength) -
      (dataProduct.length - 1);

  //On Radio Click - will read the data of current selected row
  const onRadioButtonClick = useCallback(
    (val: any) => () => {
      val?.primaryWarnings?.length > 0
        ? dispatch({
          type: STORE_ACTION_TYPES.CALCULATOR,
          payload: {
            contaminantProductKeyValues: val,
            isVaporWarning: true,
            isVaporWarningAgreed: false,
          },
        })
        : dispatch({
          type: STORE_ACTION_TYPES.CALCULATOR,
          payload: {
            contaminantProductKeyValues: val,
            isVaporWarning: false,
            isVaporWarningAgreed: true,
          },
        });
    },
    [dispatch]
  );
  //Radio Click Functionality Ends

  const findSearchTerm = () => {
    if (searchTermValue?.length == 0 || searchTermValue?.length >= CONFIG_MIN_LETTERS_FOR_SEARCH_CONTAMINANT) {
      setsearchTerm(searchTermValue);
      setDefaultSearch(true);
    }
  };

  useEffect(() => {
    setPageNumber(0);
    setNumberOfPages(pages);
    setStartPage(0);
    setLastPage(CONFIG_DEFAULT_PAGE_COLLECTION);
    /* eslint-disable */
  }, [searchTermFind, productLength, rowsPerPageValue]);

  //SetData Function

  const setData = useCallback(
    (data) => {
      setDataProductList(data?.result?.vaporList || []);
      dispatch({
        type: STORE_ACTION_TYPES.CALCULATOR,
        payload: {
          vaporSecondaryWarnings: data?.result?.secondaryWarnings || []
        },
      });
    },
    [data]
  );

  useEffect(() => {
    setData(data);
  }, [data, rowsPerPageValue]);
  //Search Functionality Click Ends

  //Using this function for setting rows per page value
  const setRowsPerPageValue = useCallback(
    (storeRowValue: string) => (e: React.ChangeEvent<HTMLSelectElement>) => {
      e.preventDefault();
      setPageNumber(0);
      dispatch({
        type: STORE_ACTION_TYPES.CALCULATOR,
        payload: { [storeRowValue]: Number(e.target.value) },
      });
      //setDefaultSearch(false);
    },
    [dispatch]
  );

  //Modal Functionality:
  const [show, setShow] = useState(false);

  const showingWarningModal = useCallback(() => {
    setShow(true);
  }, []);

  //Modal OnClose
  const setVaporDisagree = useCallback(() => {
    dispatch({
      type: STORE_ACTION_TYPES.CALCULATOR,
      payload: { isVaporWarningAgreed: false },
    });
    setShow(false);
  }, [dispatch]);

  //Modal OnOpen
  const setVaporAgree = useCallback(() => {
    dispatch({
      type: STORE_ACTION_TYPES.CALCULATOR,
      payload: { isVaporWarning: true, isVaporWarningAgreed: true },
    });
    setShow(false);
  }, [dispatch]);

  //Modal Functionality Ends

  //Setting Page Number
  const setPageItems = (e) => {
    e.preventDefault();
    const pageIndex = parseInt(e.target.innerText);
    setPageNumber(pageIndex - 1);
    !searchTermFind && setDefaultSearch(false);
  };

  //Generating Pages Array
  const range = (start, end, step = 1) => {
    const output: any[] = [];
    for (let i = start; i < end; i += step) {
      const value = i;
      output.push(value);
    }
    return output;
  };

  //Increasing Page Count
  const increasePage = (e) => {
    e.preventDefault();
    if (startPage + CONFIG_DEFAULT_PAGE_COLLECTION < numberOfPages) {
      setStartPage((prevValue) => prevValue + CONFIG_DEFAULT_PAGE_COLLECTION);
    }

    if (lastPage + CONFIG_DEFAULT_PAGE_COLLECTION < numberOfPages) {
      setLastPage((prevValue) => prevValue + CONFIG_DEFAULT_PAGE_COLLECTION);
    } else {
      setLastPage(numberOfPages);
    }
  };

  //Decreasing Page Count
  const decreasePage = (e) => {
    e.preventDefault();
    const value = lastPage - startPage;
    if (startPage - CONFIG_DEFAULT_PAGE_COLLECTION >= 0) {
      setStartPage((prevValue) => prevValue - CONFIG_DEFAULT_PAGE_COLLECTION);
    }

    if (lastPage - CONFIG_DEFAULT_PAGE_COLLECTION > CONFIG_DEFAULT_PAGE_COLLECTION) {
      setLastPage((prevValue) => prevValue - value);
    } else {
      setLastPage(CONFIG_DEFAULT_PAGE_COLLECTION);
    }
  };

  const sortDataHandler = (e, name) => {
    e.preventDefault();
    setSortDataField(name);
    setSortOrder((prevValue) => (prevValue === "asc" ? "desc" : "asc"));
  };
  //Setting Search Value
  const setSearchHandler = (event) => {
    setSearchValue(
      event.target.value.replace(
        REGEX_VALIDATION.API_INPUT_FIELD_VALIDATION,
        ""
      )
    );
    if (event.target.value === '' || event.target.value.length < 3) {
      setDefaultSearch(false);
    } else {
      setDefaultSearch(true);
      setsearchTerm(event.target.value.replace(
        REGEX_VALIDATION.API_INPUT_FIELD_VALIDATION,
        ""
      ));
    }
  }
  const debounce = (func) => {
    let timer;
    return function (this: any, ...args) {
      const context = this;
      if (timer) clearTimeout(timer)
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    }
  }
  const optimisedSearch = useCallback(debounce(setSearchHandler), []);

  const pagesOutput = range(
    startPage,
    numberOfPages < CONFIG_DEFAULT_PAGE_COLLECTION ? numberOfPages : lastPage
  );
  //getVaporWarnings
  const getVaporWarnings = useCallback((collWarning) => {
    try {
      return (
        collWarning?.length > 1 ? `<ol type="1">` +
          collWarning?.map(message => `<li><p>${message}</p></li>`)?.join("") || ""
          + `</ol>` : `<p>${collWarning?.join("")}</p>` || "");
    }
    catch {
      return "";
    }
  }, []);
  //resize parent iframe
  resizeParent();

  return (
    <div className="contaminant">
      {showError === false ?
        (
          <>
                      <div className="heading">{t(LABELKEYS_CONTAINMENT_REVIEW.MAIN_HEADING)} </div>
            <SearchBox showStatus={false} placeholder={t(LABELKEYS_CONTAINMENT_REVIEW.PLACEHOLDER)}
              onChange={optimisedSearch}
              onClick={findSearchTerm}
              displayText={t(LABELKEYS_CONTAINMENT_REVIEW.SEARCH)}
              disabled={searchTermValue.length < 3 ? true : false}
            />
            <div className="sub-head">{t(LABELKEYS_CONTAINMENT_REVIEW.SELECT_VAPOR)}</div>
            {isLoading && <><Loader /></>}
            {!isLoading && <div className="products">
              {dataProduct.length == 0 ? (<NoResults />) :
                (<>
                  <div className="containment-table">
                    <div className="panel-body">
                      <div className="table-head">
                        <div className="table-items"></div>
                        <div className="table-items">
                          {t(PRODUCT_TABLE.VAPORNAME)}{" "}
                          <a
                            href="#"
                            className="sort-by"
                            aria-label={t(ARIA_LABEL.LINK)}
                            onClick={(e) => sortDataHandler(e, "vaporName")}
                          ></a>
                        </div>
                        <div className="table-items">
                          {t(PRODUCT_TABLE.CAS)}{" "}
                          <a
                            href="#"
                            className="sort-by"
                            aria-label={t(ARIA_LABEL.LINK)}
                            onClick={(e) => sortDataHandler(e, "vaporCas")}
                          ></a>
                        </div>
                        <div className="table-items">
                          {t(PRODUCT_TABLE.WARNING)}{" "}
                        </div>
                      </div>
                      <div>
                        <div className="row_entries">
                          <Accordion flush>
                            <Form className="accordion_form_main">
                              {dataProduct?.slice(0, dataProduct.length).map((val, key) => {
                                return (
                                  <div key={key} className={contaminantProductKeyValues.vaporCas ===
                                    val.vaporCas ? "accordion_div_main selected" : "accordion_div_main"} onClick={onRadioButtonClick(val)}>
                                    <Accordion.Item eventKey={String(key)} >
                                      <Accordion.Header>
                                        <div className="table-data radio_button" aria-hidden="true">
                                          <div className="radio-btn">
                                            <input name="group1" type="radio" id={`inline-radio-${key}`} readOnly checked={
                                              contaminantProductKeyValues.vaporCas ===
                                              val.vaporCas
                                            } />
                                          </div>
                                        </div>
                                        <div className="table-data">{val.vaporName}</div>
                                        <div className="table-data">{val.vaporCas}</div>
                                        <div className="table-data">
                                          {val.primaryWarnings?.length > 0 && <a href="#" className="view-warning" onClick={showingWarningModal} aria-label={t(ARIA_LABEL.VIEW_WARNING)}>
                                            {(t(LABELKEYS_CONTAINMENT_REVIEW.WARNING))}
                                          </a>}
                                          {val.primaryWarnings?.length > 0 && <img src={ImgWarning} className="warning-icon" alt="View Warning" onClick={showingWarningModal} />}
                                          {/*MODAL */}

                                        </div>
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <div key={`div_synonym}`} className="synonym">
                                          <div className="text"> {t(LABELKEYS_WORKING_CONDITION.SYNONYM)}  <span> {val?.synonym || "-"} </span></div>
                                        </div>
                                        <div className="list-content">
                                          <div className="list-items">
                                            {FIELD_CONFIG_CONTAMINANT_SECTION1?.map((e: any) => {
                                              return (
                                                <div key={`div${e?.fieldKey}`} className="item">
                                                  {e?.fieldSource ?
                                                    <div className="info"> {t(e?.fieldLabelkey)}
                                                      {
                                                        val[e?.fieldSource] && val[e?.fieldSource]?.toString()?.toLowerCase() != "n/a" ?
                                                          <span>
                                                            {t(e?.sourceVia)}
                                                            {val[e?.fieldSource]}
                                                          </span>
                                                          :
                                                          ""
                                                      }
                                                      {val[e?.fieldUnit]?.length > 0 && val[e?.fieldUnit]?.toString()?.toLowerCase() != "n/a" ? ` (${val[e?.fieldUnit]})` : ""} </div> :
                                                    <div className="info"> {t(e?.fieldLabelkey)} </div>
                                                  }
                                                  <div className="info"> {val[e?.fieldKey] || "-"} </div>
                                                </div>
                                              );
                                            })}
                                          </div>
                                          <div className="list-items">
                                            {FIELD_CONFIG_CONTAMINANT_SECTION2?.map((e: any) => {
                                              return (
                                                <div key={`div${e?.fieldKey}`} className="item">
                                                  {e?.fieldSource ?
                                                    <div className="info">
                                                      {t(e?.fieldLabelkey)}
                                                      {
                                                        val[e?.fieldSource] && val[e?.fieldSource]?.toString()?.toLowerCase() != "n/a" ?
                                                          <span>
                                                            {t(e?.sourceVia)}
                                                            {val[e?.fieldSource]}
                                                          </span>
                                                          :
                                                          ""
                                                      }
                                                      {val[e?.fieldUnit]?.length > 0 && val[e?.fieldUnit]?.toString()?.toLowerCase() != "n/a"? ` (${val[e?.fieldUnit]})` : ""}
                                                    </div> :
                                                    <div className="info"> {t(e?.fieldLabelkey)} </div>
                                                  }
                                                  <div className="info"> {val[e?.fieldKey] || "-"} </div>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </div>
                                );
                              })}
                            </Form>
                          </Accordion>
                          <Modal show={show}>

                            {calculatorType === CALCULATION_TYPES.SERVICE_LIFE ?
                              <WarningModal showWarningIcon isHtmlMessage
                                messageHeader={t(WARNING.HEADLINE)}
                                message={getVaporWarnings(contaminantProductKeyValues?.primaryWarnings || [])}
                                onClickNo={setVaporDisagree}
                                onClickYes={setVaporAgree}
                                labelNo={t(WARNING.DISAGREE)}
                                labelYes={t(WARNING.AGREE)}
                              />
                              :
                              <WarningModal showWarningIcon isHtmlMessage
                                messageHeader={t(WARNING.HEADLINE)}
                                message={getVaporWarnings(contaminantProductKeyValues?.primaryWarnings || [])}
                                onClickYes={setVaporDisagree}
                                labelYes={t(WARNING.OK)}
                              />
                            }
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <Pagination /> */}
                  <div className="pagination">
                    <div className="pages-count">
                      {dataProduct.length == 0 ? (
                        <p></p>
                      ) : (
                        <p aria-hidden="true">
                          {" "}
                          {startPageIndex} -{" "}
                          {(pageNumber + 1) * rowsPerPageValue < productLength
                            ? (pageNumber + 1) * rowsPerPageValue
                            : productLength}{" "}
                          / {data?.result?.totalRecords} {t(PAGINATION_LABEL.ROWS)}
                        </p>
                      )}
                    </div>
                    <div className="page-numbers">
                      <ul>
                        {<li className={`arrow ${(pagesOutput.includes(0)) ? "arrow-disabled" : ""}`}><a href="#" onClick={decreasePage}>❮</a></li>}
                        {pagesOutput.map((itemIndex, index) => (
                          <li
                            key={index}
                            className={`${itemIndex === pageNumber ? "active" : ""
                              }`}
                          >
                            <a href="#" onClick={setPageItems}>
                              {itemIndex + 1}
                            </a>
                          </li>
                        ))}
                        <li className={`arrow ${pagesOutput.includes(pages - 1) ? " arrow-disabled" : ""}`}><a href="#" onClick={increasePage}>❯</a></li>
                      </ul>
                    </div>
                    <div className="rows-pages">
                      <span>{t(PAGINATION_LABEL.ROWS_PER_PAGE)}</span>
                      <select className="form-control" aria-label={t(ARIA_LABEL.PAGINATION)} value={(rowsPerPageValue)} onChange={setRowsPerPageValue("rowsPerPageValue")}>
                        {CONFIG_ROWS_PER_PAGE_OPTION?.map((val: number, key: number) => {
                          return (
                            <option key={key} value={val}>{val}</option>
                          );
                        })}
                      </select>
                      <i className="angle-down-arrow"><BiChevronDown className="angle-down-icon" /></i>
                    </div>
                  </div>
                </>)}
            </div>}
          </>) : <ErrorHandling />}
    </div>
  );
};

export default ContaminantSelection;