//Calculation Types 
export const CALCULATION_TYPES = {
  SERVICE_LIFE: "ServiceLife",
  VAPORS: "Vapors"
};
//Respirator steps 
export const RESPIRATOR_STEPS = {
  RESPIRATOR_SELECTION: "Respirator Selection",
  CARTRIDGE_SELCTION: "Product Selection",
  CONTAINMENT_SELECTION: "Contaminant Selection",
  WORKING_CONDITION: "Working Conditions",
  RESULTS: "Results",
};
//Store action type 
export const STORE_ACTION_TYPES = {
  CALCULATOR: "calculator",
  INIT: "init",
  CONFIG_QUERY: "configApi/executeQuery/fulfilled",
  PRODUCT_QUERY: "productApi/executeQuery/fulfilled",
  CONTAMINANT_QUERY: "contaminantApi/executeQuery/fulfilled",
  CALCULATION_QUERY: "calculationApi/executeQuery/fulfilled",
  CONTAMINANTWARNING_QUERY: "contaminantWarningsApi/executeQuery/fulfilled",
  RESPIRATOR_QUERY: "respiratorsApi/executeQuery/fulfilled",
  ERROR: "error",
  RESET_QUERY: "resetQuery"
};
//Navigation paths
export const NAVIGATION_PATH = {
  TERMS: "/terms-conditions",
  HOME: "/",
  STEPS: "/steps"
};
//Progress Bar Steps

export const RESPIRATOR_STEPS_COLL_SERVICELIFE = [
  RESPIRATOR_STEPS.RESPIRATOR_SELECTION,
  RESPIRATOR_STEPS.CONTAINMENT_SELECTION,
  RESPIRATOR_STEPS.CARTRIDGE_SELCTION,
  RESPIRATOR_STEPS.WORKING_CONDITION,
  RESPIRATOR_STEPS.RESULTS,
];
export const RESPIRATOR_STEPS_COLL_VAPOR = [
  RESPIRATOR_STEPS.CONTAINMENT_SELECTION,
  RESPIRATOR_STEPS.CARTRIDGE_SELCTION,
  RESPIRATOR_STEPS.RESULTS,
];
export const RESPIRATOR_STEPS_COLL = (type?: string) => {
  switch (type) {
    case CALCULATION_TYPES.SERVICE_LIFE:
      return RESPIRATOR_STEPS_COLL_SERVICELIFE;
      break;
    case CALCULATION_TYPES.VAPORS:
      return RESPIRATOR_STEPS_COLL_VAPOR;
      break;
    default:
      return RESPIRATOR_STEPS_COLL_SERVICELIFE;
      break;
  }
};
//Progress Bar Steps
export const INITIAL_STEP = RESPIRATOR_STEPS.RESPIRATOR_SELECTION;
//API Paths
export const API_PATHS = {
  CONFIGURATIONS: "configurations",
  LABEL_CONFIG_TERMS: "terms",
  LABEL_CONFIG_LABELS: "labels"
};
export const TERMS_API_PATHS = (type: string) => {
  let apiPath = "";
  if (type) {
    apiPath = API_PATHS.LABEL_CONFIG_TERMS + "?type=" + type;
  }
  return apiPath;
};
export const LANG_API_PATHS = (lang: string) => {
  let apiPath = "";
  if (lang) {
    apiPath = API_PATHS.LABEL_CONFIG_LABELS + "?lang=" + lang;
  }
  return apiPath;
};

//Particle Present Condition Values
export const PARTICLE_OPTION_VALUES = {
  YES: "Yes",
  NO: "No"
};
//LABELS CONFIG KEYS START
// Calculator selction (home) labels keys
export const LABELKEYS_BREAD_CRUMB = {
  HOME: "BREAD_CRUMB.HOME",
  LANDING: "BREAD_CRUMB.LANDING",
  RESPIRATOR: "BREAD_CRUMB.RESPIRATOR"
};

export const LABELSKEYS_CALCULATOR_SELECTION = {
    MAIN_HEADING: "CALCULATOR_SELECTION.MAIN_HEADING",
    SUPERSCRIPT_TITLE: "CALCULATOR_SELECTION.SUPERSCRIPT_TITLE",
  HEADING: "CALCULATOR_SELECTION.HEADING",
  SUB_HEADING: "CALCULATOR_SELECTION.SUB_HEADING",
  SERVICE_LIFE: "CALCULATOR_SELECTION.SERVICE_LIFE",
  VAPORS: "CALCULATOR_SELECTION.VAPORS",
  CONTINUE: "CALCULATOR_SELECTION.CONTINUE",
  CANCEL: "CALCULATOR_SELECTION.CANCEL"
};
export const LABELSKEY_TERMS = {
    MAIN_HEADING: "TERMS.MAIN_HEADING",
    SUPERSCRIPT_TITLE: "CALCULATOR_SELECTION.SUPERSCRIPT_TITLE",
  HEADING: "TERMS.HEADING",
  READ_ACCEPT: "TERMS.READ_ACCEPT",
  DISAGREE: "TERMS.DISAGREE",
  AGREE: "TERMS.AGREE",
  WARNING:"TERMS.WARNING",
  WARNINGS:"TERMS.WARNINGS",
  HTML_MARKUP: "TERMS.HTML_MARKUP"
};
export const REVIEW_KEY_PANEL_ACTION = {
  CONTINUE: "PANEL_ACTION.CONTINUE",
  BACK: "PANEL_ACTION.BACK",
  DOWNLOAD: "PANEL_ACTION.DOWNLOAD",
  SUBMIT: "PANEL_ACTION.SUBMIT",
  RESTART_CALCULATOR: "PANEL_ACTION.RESTART_CALCULATOR"
};
export const REVIEW_KEY_PANEL_ITEM = {
  REGION: "PANEL_ITEM.REGION",
  RESPIRATOR_TYPE: "PANEL_ITEM.RESPIRATOR_TYPE",
  CATRIDGE_SKU: "PANEL_ITEM.CATRIDGE_SKU",
  CHALLENGE_CONCENTRATION: "PANEL_ITEM.CHALLENGE_CONCENTRATION",
  CONTANINANT: "PANEL_ITEM.CONTANINANT",
  PARTICLE_PRESENT: "PANEL_ITEM.PARTICLE_PRESENT"
};
export const REVIEW_KEY_PANEL_ITEM_LIST = [
  REVIEW_KEY_PANEL_ITEM.REGION,
  REVIEW_KEY_PANEL_ITEM.RESPIRATOR_TYPE,
  REVIEW_KEY_PANEL_ITEM.CATRIDGE_SKU,
  REVIEW_KEY_PANEL_ITEM.CHALLENGE_CONCENTRATION,
  REVIEW_KEY_PANEL_ITEM.CONTANINANT,
];
export const PRODUCTKEYS_TABLE_LIST = {
  VAPORNAME: "TABLE_LIST.VAPORNAME",
  CAS: "TABLE_LIST.CAS",
  WARNING: "TABLE_LIST.WARNING",
};
export const LABELKEYS_RESPIRATOR_STEPS = {
    MAIN_HEADING: "RESPIRATOR_STEPS.MAIN_HEADING",
    SUPERSCRIPT_TITLE: "CALCULATOR_SELECTION.SUPERSCRIPT_TITLE",
};
export const LABELKEYS_RESPIRATOR_REVIEW = {
  HEADING: "RESPIRATOR_REVIEW.HEADING",
  NO_DATA: "RESPIRATOR_REVIEW.NO_DATA"
};
export const LABELKEYS_CATRIDGE_STEP = {
  HEADING: "CATRIDGE_STEP.HEADING",
  RESPIARTOR_CARTRIDGE_HEADING:"CATRIDGE_STEP.RESPIARTOR_CARTRIDGE_HEADING",
  PRODUCT_RESULTS: "CATRIDGE_STEP.PRODUCT_RESULTS",
  SEARCH: "CATRIDGE_STEP.SEARCH",
  LOAD_MORE: "CATRIDGE_STEP.LOAD_MORE",
  SHOWING: "CATRIDGE_STEP.SHOWING",
  SHOWING_OF: "CATRIDGE_STEP.SHOWING_OF",
  PLACEHOLDER: "CATRIDGE_STEP.PLACEHOLDER",
  RESPIRATOR_PLACEHOLDER: "CATRIDGE_STEP.RESPIRATOR_PLACEHOLDER",
  SKU: "CATRIDGE_STEP.SKU"
};
export const LABELKEYS_CATRIDGE_REVIEW = {
  HEADING: "CATRIDGE_REVIEW.HEADING",
  PRODUCT_NAME: "CATRIDGE_REVIEW.PRODUCT_NAME",
  SKU: "CATRIDGE_REVIEW.SKU",
  NO_PRODUCT: "CATRIDGE_REVIEW.NO_PRODUCT"
};

export const LABELKEYS_CONTAINMENT_REVIEW = {
    MAIN_HEADING: "CONTAINMENT_REVIEW.MAIN_HEADING",
    SUPERSCRIPT_TITLE: "CALCULATOR_SELECTION.SUPERSCRIPT_TITLE",
  SEARCH: "CONTAINMENT_REVIEW.SEARCH",
  SELECT_VAPOR: "CONTAINMENT_REVIEW.SELECT_VAPOR",
  HEADING: "CONTAINMENT_REVIEW.HEADING",
  PRODUCT_NAME: "CONTAINMENT_REVIEW.PRODUCT_NAME",
  ORGANIC_VAPOUR: "CONTAINMENT_REVIEW.ORGANIC_VAPOUR",
  CAS: "CONTAINMENT_REVIEW.CAS",
  WARNING: "CONTAINMENT_REVIEW.WARNING",
  PLACEHOLDER: "CONTAINMENT_REVIEW.PLACEHOLDER",
  WARNING_RESULT: "CONTAINMENT_REVIEW.WARNING_RESULT",
  NAME: "CONTAINMENT_REVIEW.NAME"
};

export const LABELKEYS_CONDITION_REVIEW = {
  HEADING: "CONDITION_REVIEW.HEADING",
  PRODUCT_NAME: "CONDITION_REVIEW.PRODUCT_NAME",
  ORGANIC_VAPOUR: "CONDITION_REVIEW.ORGANIC_VAPOUR",
  CAS: "CONDITION_REVIEW.CAS",
  WARNING: "CONDITION_REVIEW.WARNING",
  CONCENTRATION_NAME: "CONDITION_REVIEW.CONCENTRATION_NAME",
  CONCENTRATION_NAME_RESULT: "CONDITION_REVIEW.CONCENTRATION_NAME_RESULT",
  PEL_OSHA_CONCENTRATION_SPAN: "CONDITION_REVIEW.PEL_OSHA_CONCENTRATION_SPAN",
  CHALLENGE_CONCENTRATION_SPAN_1: "CONDITION_REVIEW.CHALLENGE_CONCENTRATION_SPAN_1",
  CHALLENGE_CONCENTRATION_SPAN_2: "CONDITION_REVIEW.CHALLENGE_CONCENTRATION_SPAN_2",
  PPM: "CONDITION_REVIEW.PPM"
};

// Respirator selction  labels
export const LABELKEYS_RESPIRATOR_SELECTION = {
  HEADING: "RESPIRATOR_SELECTION.HEADING",
  REGION: "RESPIRATOR_SELECTION.REGION",
  USA: "RESPIRATOR_SELECTION.USA",
  EMEAAPAC: "RESPIRATOR_SELECTION.EMEAAPAC",
  TYPE: "RESPIRATOR_SELECTION.TYPE",
  PAPR: "RESPIRATOR_SELECTION.PAPR",
  REUSABLE: "RESPIRATOR_SELECTION.REUSABLE",
  APR: "RESPIRATOR_SELECTION.APR"
};
//Working Condition labels
export const LABELKEYS_WORKING_CONDITION = {
  HEADLINE: "WORKING_CONDITION.HEADLINE",
  VAPOR_NAME: "WORKING_CONDITION.VAPOR_NAME",
  IDLH: "WORKING_CONDITION.IDLH",
  OEL:"WORKING_CONDITION.OEL",
  SHORT_TERM_EXPOSURE_LIMIT: "WORKING_CONDITION.SHORT_TERM_EXPOSURE_LIMIT",
  SYNONYM: "WORKING_CONDITION.SYNONYM",
  TYPE: "WORKING_CONDITION.TYPE",
  ODOR_THRESOLD: "WORKING_CONDITION.ODOR_THRESOLD",
  CEILING_EXPOSURE_LIMIT: "WORKING_CONDITION.CEILING_EXPOSURE_LIMIT",
  COMMENTS: "WORKING_CONDITION.COMMENTS",
  FILTER: "WORKING_CONDITION.FILTER",
  CHALLENGE_CONCENTRATION: "WORKING_CONDITION.CHALLENGE_CONCENTRATION",
  RATE: "WORKING_CONDITION.RATE",
  TEMPERATURE: "WORKING_CONDITION.TEMPERATURE",
  HUMIDITY: "WORKING_CONDITION.HUMIDITY",
  PLACEHOLDER_TEXT: "WORKING_CONDITION.PLACEHOLDER_TEXT",
  ADD_PLACEHOLDER_TEXT: "WORKING_CONDITION.ADD_PLACEHOLDER_TEXT",
  SELECT_CARTRIDGE_VAPOR_HEADLINE : "WORKING_CONDITION.SELECT_CARTRIDGE_VAPOR_HEADLINE",
  CAS:"WORKING_CONDITION.CAS",
  SKIN_IRRITANT:"WORKING_CONDITION.SKIN_IRRITANT",
  WARNING : "WORKING_CONDITION.WARNING",
  PPM: "WORKING_CONDITION.PPM"
};
//Results labels
export const LABELKEYS_RESULTS_REVIEW = {
  HEADLINE: "RESULTS_REVIEW.HEADLINE",
  REPRESENTATOR: "RESULTS_REVIEW.REPRESENTATOR",
  REPRESENTATOR_CONTACT: "RESULTS_REVIEW.REPRESENTATOR_CONTACT",
  EMAIL: "RESULTS_REVIEW.EMAIL",
  EMAIL_ADDRESS: "RESULTS_REVIEW.EMAIL_ADDRESS",
  SUPPORT: "RESULTS_REVIEW.SUPPORT",
  BUY_OPTION: "RESULTS_REVIEW.BUY_OPTION",
  SHOP: "RESULTS_REVIEW.SHOP",
  DISTRIBUTER_CENTER: "RESULTS_REVIEW.DISTRIBUTER_CENTER"
};
export const LABELKEYS_RESULTS_REVIEW_REGION_ITEMS = (type, region) => {
  switch (type) {
    case LABELKEYS_RESULTS_REVIEW.EMAIL_ADDRESS:
      return `RESULTS_REVIEW.${region}.EMAIL_ADDRESS`;   
    case LABELKEYS_RESULTS_REVIEW.REPRESENTATOR_CONTACT:
      return `RESULTS_REVIEW.${region}.REPRESENTATOR_CONTACT`;  
      default:
        return "-";

  }
};
//Condition Calculation Label
export const LABELKEYS_CALCULATION_CONDITION = {
  CONCENTRATION_VALUE: "CALCULATION_CONDITION.CONCENTRATION_VALUE",
  RATE_VALUE: "CALCULATION_CONDITION.RATE_VALUE",
  TEMPERATURE_VALUE: "CALCULATION_CONDITION.TEMPERATURE_VALUE",
  HUMIDITY_VALUE: "CALCULATION_CONDITION.HUMIDITY_VALUE"
};
//Results Label
export const LABELKEYS_RESULTS = {
  HEADLINE: "RESULTS_LABELS.HEADLINE",
  HOUR:"RESULTS_LABELS.HOUR",
  SUB_HEADLINE: "RESULTS_LABELS.SUB_HEADLINE",
  TIME: "RESULTS_LABELS.TIME",
  WORKING_CONDITION: "RESULTS_LABELS.WORKING_CONDITION",
  CARTRIDGE_SELECTION: "RESULTS_LABELS.CARTRIDGE_SELECTION",
  EXPECTED_VAPOR:"RESULTS_LABELS.EXPECTED_VAPOR",
  REGION_AND_RESPIRATOR:"RESULTS_LABELS.REGION_AND_RESPIRATOR",
  PART_NUMBER: "RESULTS_LABELS.PART_NUMBER",
  SKU: "RESULTS_LABELS.SKU",
  SELECT_CARTRIDGE_HEADLINE_FIRST : "RESULTS_LABELS.SELECT_CARTRIDGE_HEADLINE_FIRST",
  SELECT_CARTRIDGE_HEADLINE_SECOND : "RESULTS_LABELS.SELECT_CARTRIDGE_HEADLINE_SECOND",
  RESPIRATOR_OPTION_CARTRIDGE_SELECTION: "RESULTS_LABELS.RESPIRATOR_OPTION_CARTRIDGE_SELECTION",
  RESPIRATOR_OPTION_COMPATIBLE: "RESULTS_LABELS.RESPIRATOR_OPTION_COMPATIBLE",
  RESPIRATOR_OPTION_CARTRIDGE_SELECTION_LABEL:"RESULTS_LABELS.RESPIRATOR_OPTION_CARTRIDGE_SELECTION_LABEL",
  HOURS:"RESULTS_LABELS.HOURS"
};
//WARNING LABEL FOR RESPIRATOR
export const REVIEW_KEY_PANEL_WARNINGS = {
  NO_REGION_RESPIRATOR: "REVIEW_PANEL_WARNING.NO_REGION_RESPIRATOR",
  NO_REGION: "REVIEW_PANEL_WARNING.NO_REGION",
  NO_RESPIRATOR: "REVIEW_PANEL_WARNING.NO_RESPIRATOR"
};
//CONATINMENT PRODUCT LIST
export const PRODUCT_TABLE = {
  VAPORNAME: "PRODUCT_TABLE_LISTS.VAPORNAME",
  CAS: "PRODUCT_TABLE_LISTS.CAS",
  WARNING: "PRODUCT_TABLE_LISTS.WARNING",
};

//ARIA-LABLES
export const ARIA_LABEL = {
  BREADCRUMBS: "ARIA_LABELS.BREADCRUMBS",
  RESPIRATOR_CALCULTAION: "ARIA_LABELS.RESPIRATOR_CALCULTAION",
  PAGINATION: "ARIA_LABELS.PAGINATION",
  CHALLENGE_CONCENTRATION: "ARIA_LABELS.CHALLENGE_CONCENTRATION",
  RATE: "ARIA_LABELS.RATE",
  TEMPERATURE: "ARIA_LABELS.TEMPERATURE",
  HUMIDITY: "ARIA_LABELS.HUMIDITY",
  LINK: "ARIA_LABELS.LINK",
  VIEW_WARNING: "ARIA_LABELS.VIEW_WARNING"
};

//NO RESULTS LABEL
export const NO_RESULT = {
  HEADLINE: "NO_RESULTS.HEADLINE",
  SUBHEADLINE: "NO_RESULTS.SUBHEADLINE",
  TECHNICALSUPPORT: "NO_RESULTS.TECHNICALSUPPORT"
};
//WARNING LABEL
export const WARNING = {
  HEADLINE: "WARNINGS.HEADLINE",
  MESSAGE: "WARNINGS.MESSAGE",
  AGREE: "WARNINGS.AGREE",
  DISAGREE: "WARNINGS.DISAGREE",
  WARNING_REVIEW_HEADLINE: "WARNINGS.WARNING_REVIEW_HEADLINE",
  WARNING_REVIEW_SUBHEADLINE: "WARNINGS.WARNING_REVIEW_SUBHEADLINE",
  YES : "WARNINGS.YES",
  NO: "WARNINGS.NO",
  OK: "WARNINGS.OK",
  ALERT: "WARNINGS.ALERT",
  RESTART:"WARNINGS.RESTART",
  BACK:"WARNINGS.BACK",
  ERROR: "WARNINGS.ERROR",
  DISCLAIMER_DESCRIPTION: "WARNINGS.DISCLAIMER_DESCRIPTION",
  DISCLAIMER_HEADLINE: "WARNINGS.DISCLAIMER_HEADLINE",
  DISABLED_FLOW_HEADLINE: "WARNINGS.DISABLED_FLOW_HEADLINE",
  DISABLED_FLOW_MESSAGE: "WARNINGS.DISABLED_FLOW_MESSAGE",
  DISABLED_FLOW_BUTTON_TEXT: "WARNINGS.DISABLED_FLOW_BUTTON_TEXT",
  SUGGESTED:"WARNINGS.SUGGESTED",
  SUGGESTED_RESPIRATOR_DESCRIPTION:"WARNINGS.SUGGESTED_RESPIRATOR_DESCRIPTION"
};
//PROGRESS BAR MOBILE LABEL
export const PROGRESS_BAR = {
  SHOW : "PROGRESSBAR.SHOW",
  OF : "PROGRESSBAR.OF"
};
//PAGINATION_CONTAMINANT
export const PAGINATION_LABEL = {
  ROWS: "PAGINATION.ROWS",
  ROWS_PER_PAGE: "PAGINATION.ROWS_PER_PAGE",
};

//PARTICLES PORION RESPIRATOR SELECTION
export const PARTICLE_PRESENT_RESPIRATOR = {
  PARTICLE_HEADLINE: "PARTICLE_PRESENT.PARTICLE_HEADLINE",
  INDUSTRY_HEADLINE: "PARTICLE_PRESENT.INDUSTRY_HEADLINE",
  INDUSTRY_HEADLINE_OPTIONAL: "PARTICLE_PRESENT.INDUSTRY_HEADLINE_OPTIONAL",
  YES: "PARTICLE_PRESENT.YES",
  NO: "PARTICLE_PRESENT.NO",
  HELPTEXT: "PARTICLE_PRESENT.HELPTEXT"
};

//LABELS CONFIG KEYS END
//LABELS START
// Calculator selction (home) labels
export const LABELS_CALCULATOR_SELECTION = {
  MAIN_HEADING: "Respirator Calculator",
  HEADING: "What are you looking for?",
  SUB_HEADING: "I want to find:",
  SERVICE_LIFE: "the service life of my selected cartridge against my work conditions ",
  VAPORS: "which cartridge is the best fit for the vapors that I will be exposed to",
  CONTINUE: "CONTINUE",
  CANCEL: "CANCEL"
};

// Bread crumb labels
export const LABELS_BREAD_CRUMB = {
  HOME: "Home",
  LANDING: "Calculator Landing",
  RESPIRATOR: "Respirator Calculator"
};
export const LABELS_TERMS = {
  MAIN_HEADING: "Respirator Calculator",
  HEADING: "Terms and Conditions",
  READ_ACCEPT: "I have read and accept the terms & conditions.",
  DISAGREE: "DISAGREE",
  AGREE: "AGREE"
};
export const REVIEW_PANEL_ACTION = {
  CONTINUE: "CONTINUE",
  BACK: "BACK",
  DOWNLOAD: "DOWNLOAD PDF",
  SUBMIT: "SUBMIT",
  RESTART_CALCULATOR: "Restart Calculator"
};
export const REVIEW_PANEL_WARNINGS = {
  NO_REGION_RESPIRATOR: "No Region and Respirator Type selected",
  NO_REGION: "No Region selected",
  NO_RESPIRATOR: "No Respirator Type selected"
};
export const REVIEW_PANEL_ITEM = {
  REGION: "Region",
  RESPIRATOR_TYPE: "Respirator Type",
  CATRIDGE_SKU: "Cartridge SKU",
  CHALLENGE_CONCENTRATION: "Challenge Concentration",
  CONTANINANT: "Contaminant"
};
export const REVIEW_PANEL_ITEM_LIST = [
  REVIEW_PANEL_ITEM.REGION,
  REVIEW_PANEL_ITEM.RESPIRATOR_TYPE,
  REVIEW_PANEL_ITEM.CATRIDGE_SKU,
  REVIEW_PANEL_ITEM.CHALLENGE_CONCENTRATION,
  REVIEW_PANEL_ITEM.CONTANINANT,
];
export const PRODUCT_TABLE_LIST = {
  VapourName: "Vapour Name",
  CAS: "CAS",
  Warning: "Warning",
};
export const LABELS_RESPIRATOR_STEPS = {
  MAIN_HEADING: "Respirator Calculator",
};
export const LABELS_RESPIRATOR_REVIEW = {
  HEADING: "Your Region and Respirator",
  NO_DATA: "No Data"
};
export const LABELS_CATRIDGE_STEP = {
  HEADING: "Cartridge Selection",
  PRODUCT_RESULTS: "Results",
  SEARCH: "SEARCH",
  LOAD_MORE: "LOAD MORE"
};
export const LABELS_CATRIDGE_REVIEW = {
  HEADING: "Your Cartridge",
  PRODUCT_NAME: "Product Name",
  SKU: "SKU : "
};

export const LABELS_CONTAINMENT_REVIEW = {
  HEADING: "Expected Organic Vapor",
  PRODUCT_NAME: "Product Name",
  ORGANIC_VAPOUR: "Organic Vapour",
  CAS: "CAS",
  WARNING: "View Warning",
  WARNING_RESULT: "Warning(s)",
  NAME: "Name"
};

export const LABELS_CONDITION_REVIEW = {
  HEADING: "Your Conditions",
  PRODUCT_NAME: "Product Name",
  ORGANIC_VAPOUR: "Organic Vapour",
  CAS: "CAS",
  WARNING: "View Warning",
  CONCENTRATION_NAME: "Breakthrough Concentration",
  PEL_OSHA_CONCENTRATION_SPAN: "(1/2 exposure limit PEL OSHA)",
  CHALLENGE_CONCENTRATION_SPAN: "(at 10% of Challenge)"
};

// Respirator selction  labels
export const LABELS_RESPIRATOR_SELECTION = {
  HEADING: "Respirator Selection",
  REGION: "Region",
  USA: "USA",
  EMEAAPAC: "EMEA/APAC",
  TYPE: "Respirator Type",
  PAPR: "PAPR",
  REUSABLE: "Reusable"
};
//Working Condition labels
export const LABELS_WORKING_CONDITION = {
  HEADLINE: "Working Conditions",
  VAPOR_NAME: "Carbon Tetrachloride (CAS: 56-23-5)",
  IDLH: "IDLH (ppm)",
  SHORT_TERM_EXPOSURE_LIMIT: "Short Term Exposure Limit",
  SYNONYM: "Synonym",
  TYPE: "Respirator Type",
  ODOR_THRESOLD: "Odor Threshold",
  CEILING_EXPOSURE_LIMIT: "Ceiling Exposure Limit",
  COMMENTS: "Comments",
  FILTER: "Filter, Cartridge or Supplied Air",
  CHALLENGE_CONCENTRATION: "Challenge Concentration (ppm)",
  RATE: "Rate (LPM)",
  TEMPERATURE: "Temperature (°C)",
  HUMIDITY: "Relative Humidity (%)",
  PLACEHOLDER_TEXT: "This is a Mandatory Field"
};
//Results labels
export const LABELS_RESULTS_REVIEW = {
  HEADLINE: "Contact A Specialist",
  REPRESENTATOR: "Talk to a Live Representative",
  REPRESENTATOR_CONTACT: "855-655-8981",
  EMAIL: "E-mail",
  EMAIL_ADDRESS: "support@ppe.honeywell.com",
  SUPPORT: "Support",
  BUY_OPTION: "Where to Buy"
};
//Calculation Working Condtion Lables
export const LABELS_CALCULATION_CONDITION = {
  CONCENTRATION_VALUE: "concentrationValue",
  RATE_VALUE: "rateValue",
  TEMPERATURE_VALUE: "temperatureValue",
  HUMIDITY_VALUE: "humidityValue"
};

//Analytics Industry Particle Lables
export const LABELS_INDUSTRY_VALUE = {
  INDUSTRY_VALUE: "industryValue"
};

//Results Section label
export const LABELS_RESULTS = {
  HEADLINE: "Results",
  SUB_HEADLINE: "Estimated Service Life",
  TIME: "59.26 Minutes",
  WORKING_CONDITION: "Your Working Conditions",
  CARTRIDGE_SELECTION: "Your Cartridge Selection"
};
//LABELS END

//Analytics urls
export const ANALYTICS_URL = "https://assets.adobedtm.com/ccbae5a917c7/73183154bb6a/launch-af15a4a69f38-development.min.js";
export const ANALYTICS_URLS = {
  DEV: "https://assets.adobedtm.com/ccbae5a917c7/73183154bb6a/launch-af15a4a69f38-development.min.js"
};

//Results Link URL
export const RESULT_LINK_URL = {
  SUPPORT: "https://www.honeywell.com/us/en/privacy-statement",
  WHERE_TO_BUY: "https://www.honeywell.com/us/en/terms-and-conditions",
  SHOP: "https://www.honeywell.com/us/en/privacy-statement",
  DISTRIBUTER_CENTER: "https://www.honeywell.com/us/en/terms-and-conditions"
};

//Error Messages
export const ERROR_MESSAGES = {
  CONFIG_NOT_FOUND: "Error: Cannot access configuration files for this app",
  LANG_FILE_NOT_FOUND: "Error: Cannot access language files for this app",
  ERROR_HANDLING_MESSAGE : "ERROR_HANDLING.ERROR_HANDLING_MESSAGE",
  ERROR_HANDLING_SUB_HEADLINE:"ERROR_HANDLING.ERROR_HANDLING_SUB_HEADLINE",
  ERROR_HANDLING_HEADLINE:"ERROR_HANDLING.ERROR_HANDLING_HEADLINE"
};

//REGEX EXPRESSION VALIDATION
export const REGEX_VALIDATION = {
  API_INPUT_FIELD_VALIDATION: /[!@#%&*~`^$_+\=\[\]{};':"\\|.<>\/?]+/gi
};
export const REGEX_VALIDATION_CHALLENGE_CONCENTRATION = {
  API_INPUT_FIELD_VALIDATION: /[+!@#%&*~`^$_+\=\[\]{};':"\\|<>\/?]+/gi
};
export const REGEX_VALIDATION_INDUSTRY = {
  API_INPUT_FIELD_VALIDATION: /[0-9!@#%&*~`^$)(_+\-=\[\]{};':"\\|.,<>\/?]+/gi
};
export const LABELKEYS_PDF = {
  HEADLINE_SELECT_CARTRIDGE_PDF : "LABELS_PDF.HEADLINE_SELECT_CARTRIDGE_PDF",
  HEADLINE_SERVICE_LIFE_PDF:"LABELS_PDF.HEADLINE_SERVICE_LIFE_PDF",
  EMAIL : "LABELS_PDF.EMAIL",
  TECHNICAL_SUPPORT : "LABELS_PDF.TECHNICAL_SUPPORT",
  WEB_URL : "LABELS_PDF.WEB_URL"
};
